/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FormEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Theme from '@material-ui/core/styles/MuiThemeProvider';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { DemandData } from '../..';
import colorContext from '../../../../Context/colorContext';
import { searchCep } from '../../../../services/ouvidoria';
import { cepMask } from '../../../../utils/cepFormat';

interface LocalizationProps {
  controlStep: (action: 'prev' | 'next') => void;
  changeDemandData: (data: DemandData) => void;
  demandData: DemandData;
}

export default function Localization({
  controlStep,
  changeDemandData,
  demandData,
}: LocalizationProps): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    boxStepContent: {
      padding: '0px 10px',
      marginTop: 48,
    },
    actionTitle: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 500,
      textAlign: 'center',
      color: colors.textBody,
      marginBottom: 48,
      [theme.breakpoints.down(660)]: {
        padding: '0px 10px',
        fontSize: theme.typography.pxToRem(18),
        marginBottom: 32,
      },
    },
    warning: {
      display: 'block',
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    grid: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    form: {
      width: '100%',
    },
    menuItem: {
      fontSize: theme.typography.pxToRem(16),
      color: colors.textBody,
      fontWeight: 500,
    },
    buttonAdd: {
      width: 56,
      height: 56,
      margin: '0 auto',
      border: '1px solid #DAE1EF',
      borderRadius: 4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      fontFamily: 'Roboto',
      [theme.breakpoints.down(660)]: {
        width: '100%',
        height: 'auto',
        padding: '16px 0px',
        gap: 13,
        fontSize: theme.typography.pxToRem(15),
        color: colors.textBody,
        fontWeight: 500,
        textTransform: 'uppercase',
      },
    },
    iconAdd: {
      width: 24,
      height: 24,
      color: colors.textBody,
    },
    boxActions: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 24,
      paddingBottom: '56px',
      [theme.breakpoints.down(660)]: {
        flexDirection: 'column',
        gap: 30,
      },
    },
    warningBottom: {
      fontSize: theme.typography.pxToRem(14),
      color: '#4F5766',
      fontWeight: 400,
    },
    boxButton: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      [theme.breakpoints.down(660)]: {
        width: '100%',
      },
    },
    button: {
      padding: '12px 43px',
      border: 'none',
      borderRadius: 4,
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(16),
      cursor: 'pointer',
      fontWeight: 500,
      fontFamily: 'Roboto',
      [theme.breakpoints.down(660)]: {
        width: '100%',
        padding: '12px 0px',
      },
    },
    forwardButton: {
      color: colors.textAccentColor,
      backgroundColor: colors.accentColorBackground,
    },
    backButton: {
      display: 'flex',
      alignItems: 'center',
      gap: 11,
      padding: ' 8px, 22px, 8px, 16px',
      border: 'none',
      backgroundColor: colors.colorBackground,
      color: colors.textBody,
      [theme.breakpoints.down(660)]: {
        justifyContent: 'center',
      },
    },
    icon: {
      width: 18,
      height: 18,
      color: colors.textBody,
    },
    borderError: {
      border: `1px solid ${colors.buttonErrorColor}`,
    },
  }));

  const classes = useStyles();
  const [city, setCity] = useState('Salvador');
  const [loadingCep, setLoadingCep] = useState<boolean>(false);
  const [codeCep, setCodeCep] = useState<string | null>();
  const [stateAddress, setStateAddress] = useState<string>();
  const [district, setDistrict] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [addressNumber, setAddressNumber] = useState<string>();
  const [complementAddress, setComplementAddress] = useState<string>();
  const [pointReference, setPointReference] = useState<string>();
  const [errorCep, setErrorCep] = useState<string | null>(null);

  const createLocalizationData = (event: FormEvent) => {
    event.preventDefault();
    changeDemandData({
      bairro: district,
      cep: codeCep,
      logradouro: address,
      cidade: city,
      estado: stateAddress,
      complemento: complementAddress,
      numero: addressNumber,
      ponto_referencia: pointReference,
      ...demandData,
    });
    controlStep('next');
  };

  const handleSearchAddress = async () => {
    if (!codeCep) return;
    try {
      setLoadingCep(true);
      const { data } = await searchCep(codeCep, 'Salvador');

      setDistrict(data.bairro);
      setAddress(data.logradouro);
      setCity(data.cidade);
      setStateAddress(data.uf);
    } catch (error) {
      if (error?.response?.data) {
        setErrorCep(error?.response?.data?.message);
      }
      console.error(error);
    } finally {
      setLoadingCep(false);
    }
  };

  const SearchButton = () => (
    <IconButton onClick={handleSearchAddress}>
      <SearchIcon />
    </IconButton>
  );

  useEffect(() => {
    const keyDownHandle = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSearchAddress();
      }
    };

    document.addEventListener('keydown', keyDownHandle);

    return () => {
      document.removeEventListener('keydown', keyDownHandle);
    };
  }, [handleSearchAddress]);

  return (
    <Box className={classes.boxStepContent}>
      <form onSubmit={createLocalizationData}>
        <Box width="100%" marginTop="48px" marginBottom="40px">
          <Typography className={classes.actionTitle}>
            Informe o local em que ocorreu a situação relatada
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  id="outlined-full-width"
                  label="CEP"
                  required
                  fullWidth
                  value={codeCep}
                  onChange={(event) => {
                    setCodeCep(cepMask(event.target.value));
                    setErrorCep(null);
                  }}
                  variant="outlined"
                  InputProps={{
                    endAdornment: <SearchButton />,
                    style: {
                      color: '#1F2937',
                    },
                  }}
                  error={(errorCep) !== null}
                  helperText={errorCep || ''}
                  onBlur={() => handleSearchAddress()}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  id="outlined-full-width"
                  label="Logradouro"
                  required
                  fullWidth
                  value={address}
                  onChange={(event) => setAddress(event.target.value)}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: (address !== ''),
                  }}
                  disabled={loadingCep}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  id="outlined-full-width"
                  required
                  label="Bairro"
                  className={classes.menuItem}
                  value={district}
                  onChange={(event) => setDistrict(event.target.value)}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: (district !== ''),
                  }}
                  disabled={loadingCep}
                />

              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  id="outlined-full-width"
                  required
                  label="Número"
                  className={classes.menuItem}
                  value={addressNumber}
                  onChange={(event) => setAddressNumber(event.target.value)}
                  variant="outlined"
                />

              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  id="outlined-full-width"
                  label="Complemento"
                  className={classes.menuItem}
                  value={complementAddress}
                  onChange={(event) => setComplementAddress(event.target.value)}
                  variant="outlined"
                />

              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  id="outlined-full-width"
                  required
                  label="Ponto de Referência"
                  className={classes.menuItem}
                  value={pointReference}
                  onChange={(event) => setPointReference(event.target.value)}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.boxActions}>
          <span className={classes.warningBottom}>
            * Campo de preenchimento obrigatório
          </span>
          <Box className={classes.boxButton}>
            <button
              type="button"
              onClick={() => controlStep('prev')}
              className={`${classes.button} ${classes.backButton}`}
            >
              <ArrowBackIcon className={classes.icon} />
              Voltar
            </button>
            <button
              disabled={
              codeCep?.length !== 9
            }
              type="submit"
              className={`${classes.button} ${classes.forwardButton}`}
              style={{
                opacity:
                  codeCep?.length !== 9
                  || complementAddress?.length === 0
                  || district?.length === 0
                  || addressNumber?.length === 0
                  || address?.length === 0
                    ? '0.7'
                    : '1',
              }}
            >
              Avançar
            </button>
          </Box>
        </Box>
      </form>
    </Box>
  );
}
