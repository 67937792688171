/* eslint-disable default-case */
import React, { useContext, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Theme from '@material-ui/core/styles/MuiThemeProvider';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { DemandData } from '../..';
import { formatBytes } from '../../../../utils/formatBytes';
import colorContext from '../../../../Context/colorContext';
import {
  AttachDocumentDemand,
  registerDemandAuth,
} from '../../../../services/ouvidoria';
import getCookie from '../../../../utils/getCookies';

interface ConclusionProps {
  controlStep: (action: 'prev' | 'next') => void;
  changeDemandCodes: (code: string, protocol: string) => void;
  demandaData: DemandData;
}

export default function Conclusion({
  controlStep,
  demandaData,
  changeDemandCodes,
}: ConclusionProps): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    boxStepContent: {
      padding: '0px 10px',
      marginTop: 48,
    },
    actionTitle: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 500,
      textAlign: 'center',
      color: colors.textBody,
      marginBottom: 48,
      [theme.breakpoints.down(660)]: {
        padding: '0px 16px',
        fontSize: theme.typography.pxToRem(18),
      },
    },
    ticket: {
      width: '100%',
      maxWidth: '1320px',
      marginBottom: '100px',
      padding: '0px 16px',
    },
    grid: {
      padding: '40px 80px',
      [theme.breakpoints.down(721)]: {
        padding: '20px 40px',
      },
      [theme.breakpoints.down(600)]: {
        padding: '56px 47px 64px 47px',
      },
    },
    informationTitle: {
      fontSize: theme.typography.pxToRem(14),
      color: colors.textBody,
      fontWeight: 700,
      display: 'block',
      marginBottom: 7,
      [theme.breakpoints.down(600)]: {
        textAlign: 'center',
      },
    },
    description: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      color: colors.textBody,
      display: 'block',
      wordBreak: 'break-word',
      [theme.breakpoints.down(600)]: {
        textAlign: 'center',
      },
    },
    manifestationDescription: {
      maxHeight: 110,
      overflow: 'hidden',
      whiteSpace: 'pre-line',
    },
    boxButton: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 24,
      gap: 16,
      paddingBottom: '56px',
    },
    button: {
      padding: '12px 43px',
      border: 'none',
      borderRadius: 4,
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(16),
      cursor: 'pointer',
      fontWeight: 500,
      [theme.breakpoints.down(660)]: {
        width: '100%',
        padding: '12px 0px',
      },
    },
    forwardButton: {
      color: colors.textAccentColor,
      backgroundColor: colors.accentColorBackground,
    },
    backButton: {
      display: 'flex',
      alignItems: 'center',
      gap: 11,
      padding: ' 8px, 22px, 8px, 16px',
      border: 'none',
      backgroundColor: colors.colorBackground,
      color: colors.textBody,
      [theme.breakpoints.down(660)]: {
        justifyContent: 'center',
      },
    },
    icon: {
      width: 18,
      height: 18,
      color: colors.textBody,
    },
    moreContent: {
      display: 'block',
      textAlign: 'left',
      [theme.breakpoints.down(600)]: {
        textAlign: 'center',
      },
    },
    boxAccordion: {
      width: '100%',
      margin: '24px 32px',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
    },
    accordionDetail: {
      padding: '0px 42px 16px 16px',
      wordBreak: 'break-word',
      backgroundColor: colors.colorBackgroundSecundary,
      [theme.breakpoints.down('xs')]: {
        paddingRight: 16,
      },
    },
    accordion: {
      border: '1px solid #DAE1EF',
      marginBottom: 20,
      width: '100%',
      boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.04)',
      '&::before': {
        display: 'none',
      },
      borderRadius: 4,
    },
    accordionRoot: {
      minHeight: 62,
      backgroundColor: '#F6F8FC',
      borderBottom: '1px solid #DAE1EF',
    },
    accordionIcon: {
      width: 32,
      height: 32,
      color: '#1F2937',
      [theme.breakpoints.down(660)]: {
        width: 24,
        height: 24,
      },
    },
    boxContent: {
      margin: '48px 32px 43px 32px',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        margin: '20px 43px 32px 4px',
      },
    },
    titleAccordion: {
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 600,
      color: '#1F2937',
      marginLeft: 32,
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(18),
        marginLeft: 0,
      },
    },
    label: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
      color: '#1F2937',
      marginBottom: 7,
    },
    value: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      color: '#1F2937',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    separate: {
      marginBottom: 20,
    },
  }));
  const token: string | null = getCookie('gov_access_token_sso');
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const matches = useMediaQuery('(max-width: 600px)');
  const [expanded, setExpanded] = useState<string | false>(false);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const createRecord = async () => {
    setLoading(true);
    switch (demandaData?.tipo_identificacao) {
      case 'Identificado':
        try {
          const { data } = await registerDemandAuth(
            token,
            demandaData?.conteudo,
            demandaData?.tipo_identificacao,
            demandaData?.servico_slug,
            demandaData?.servico_titulo,
            demandaData?.servico_descricao,
            demandaData?.setor_id,
            demandaData?.canal_entrada,
            demandaData?.orgao,
            demandaData?.bairro,
            demandaData?.numero,
            demandaData?.cep,
            demandaData?.cidade,
            demandaData?.logradouro,
            demandaData?.estado,
            demandaData?.complemento,
            demandaData?.tempo_total,
            demandaData?.tipo_tempo,
            demandaData?.aceite,
          );
          if (data && demandaData?.arquivos.length >= 1) {
            try {
              // eslint-disable-next-line no-restricted-syntax
              for (const fileUpload of demandaData?.arquivos) {
                AttachDocumentDemand(
                  data.id,
                  fileUpload?.file?.name,
                  fileUpload?.file,
                );
              }
            } catch (err) {
              console.log(err);
            }
          }
          changeDemandCodes(
            null,
            data.protocolo,
          );
          setLoading(false);
          controlStep('next');
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
        break;

      case 'Sigiloso':
        try {
          const { data } = await registerDemandAuth(
            token,
            demandaData?.conteudo,
            demandaData?.tipo_identificacao,
            demandaData?.servico_slug,
            demandaData?.servico_titulo,
            demandaData?.servico_descricao,
            demandaData?.setor_id,
            demandaData?.canal_entrada,
            demandaData?.orgao,
            demandaData?.bairro,
            demandaData?.numero,
            demandaData?.cep,
            demandaData?.cidade,
            demandaData?.logradouro,
            demandaData?.estado,
            demandaData?.complemento,
            demandaData?.tempo_total,
            demandaData?.tipo_tempo,
            demandaData?.aceite,
          );
          if (data && demandaData?.arquivos?.length >= 1) {
            try {
              // eslint-disable-next-line no-restricted-syntax
              for (const fileUpload of demandaData?.arquivos) {
                AttachDocumentDemand(
                  data.id,
                  fileUpload?.file?.name,
                  fileUpload?.file,
                );
              }
            } catch (err) {
              console.log(err);
            }
          }
          changeDemandCodes(
            demandaData?.codigo_anonimo,
            data.protocolo,
          );
          setLoading(false);
          controlStep('next');
        } catch (error) {
          console.log(error);
        }
    }
  };

  return (
    <Box className={classes.boxStepContent}>
      <Typography className={classes.actionTitle}>
        {`Confirme as informações preenchidas antes de enviar para a ${demandaData?.orgao_name}`}
      </Typography>
      <Box
        className={classes.ticket}
      >
        <Accordion
          defaultExpanded
          className={classes.accordion}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            classes={{ root: classes.accordionRoot }}
            expandIcon={
                expanded === 'panel1' ? (
                  <ExpandMoreIcon className={classes.accordionIcon} />
                ) : (
                  <ExpandMoreIcon className={classes.accordionIcon} />
                )
              }
          >
            <Typography className={classes.titleAccordion}>
              Serviço, órgão e tipo de identificação
            </Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetail }}>
            <Box className={classes.boxContent}>
              <Grid container spacing={matches ? 3 : 6}>
                <Grid item xs={12} sm={4}>
                  <Box className={classes.separate}>
                    <Typography className={classes.label}>Serviço</Typography>
                    <span className={classes.value}>
                      {demandaData?.servico_titulo || 'Sem resultados.'}
                    </span>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <Typography className={classes.label}>Tipo de Identificação</Typography>
                    <span className={classes.value}>
                      {demandaData?.tipo_identificacao || 'Sem resultados.'}
                    </span>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <Typography className={classes.label}>Órgão Destinatário</Typography>
                    <span className={classes.value}>
                      {demandaData?.orgao_name || 'Sem resultados.'}
                    </span>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          defaultExpanded
          className={classes.accordion}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            classes={{ root: classes.accordionRoot }}
            expandIcon={
                expanded === 'panel2' ? (
                  <ExpandMoreIcon className={classes.accordionIcon} />
                ) : (
                  <ExpandMoreIcon className={classes.accordionIcon} />
                )
              }
          >
            <Typography className={classes.titleAccordion}>
              Local / Endereço
            </Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetail }}>
            <Box className={classes.boxContent}>
              <Grid container spacing={matches ? 3 : 6}>
                <Grid item xs={12} sm={4}>
                  <Typography className={classes.label}>Endereço</Typography>
                  <span className={classes.value}>
                    {demandaData?.logradouro || 'Sem resultados.'}
                    {' '}
                    -
                    {' '}
                  </span>
                  <div>
                    {demandaData?.bairro}
                  </div>
                  <div>
                    {`${demandaData?.cidade}(BA)`}
                  </div>
                  <div>
                    {demandaData?.cep}
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className={classes.label}>Complemento</Typography>
                  <span className={classes.value}>
                    {demandaData?.complemento || 'Sem resultados.'}
                  </span>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className={classes.label}>Ponto de referência</Typography>
                  <span className={classes.value}>
                    {demandaData?.ponto_referencia || 'Sem resultados.'}
                  </span>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          defaultExpanded
          className={classes.accordion}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            classes={{ root: classes.accordionRoot }}
            expandIcon={
                expanded === 'panel3' ? (
                  <ExpandMoreIcon className={classes.accordionIcon} />
                ) : (
                  <ExpandMoreIcon className={classes.accordionIcon} />
                )
              }
          >
            <Typography className={classes.titleAccordion}>
              Demanda e arquivos
            </Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetail }}>
            <Box className={classes.boxContent}>
              <Grid container spacing={matches ? 3 : 6}>
                <Grid item xs={12} sm={4} md={12}>
                  <Box className={classes.separate}>
                    <Typography className={classes.label}>Teor da demanda</Typography>
                    <span className={classes.value}>
                      {demandaData?.conteudo || 'Sem resultados.'}
                    </span>
                  </Box>

                  <Box>
                    <Typography className={classes.label}>Anexo</Typography>

                    <span className={classes.value}>
                      {demandaData?.arquivos?.length > 0 ? (
                        <>
                          {demandaData?.arquivos?.map((file) => (
                            <span
                              className={classes.description}
                              style={{ marginBottom: 10 }}
                              key={file?.file?.name}
                            >
                              {file?.file?.name}
                              -
                              {formatBytes(file.file?.size)}
                            </span>
                          ))}
                        </>
                          ) : (
                            <span className={classes.description}>
                              Nenhum arquivo anexado
                            </span>
                          )}
                    </span>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box className={classes.boxButton}>
        <button
          type="button"
          onClick={() => controlStep('prev')}
          className={`${classes.button} ${classes.backButton}`}
          disabled={loading}
        >
          <ArrowBackIcon className={classes.icon} />
          Voltar
        </button>
        <button
          type="button"
          onClick={createRecord}
          className={`${classes.button} ${classes.forwardButton}`}
        >
          {loading ? (
            <CircularProgress
              style={{ width: 15, height: 15, color: '#fff' }}
            />
          ) : (
            'Confirmar'
          )}
        </button>
      </Box>
    </Box>
  );
}
